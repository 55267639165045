import React, { useLayoutEffect, useState } from "react";
import twitter from "../../assets/images/twitter.png";
import facebook from "../../assets/images/facebook.png";
import linkedin from "../../assets/images/linkedin.png";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import Carousel from "./Carousel";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

export default function QuizResult({ challengeQuestion, userAnswers }) {
  const [userScore, setUserScore] = useState(0);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    let score = 0;
    challengeQuestion.map((quizCard, index) => {
      if (quizCard?.answer === userAnswers[index]) {
        score += 1;
      }
      setUserScore(score);
      return userScore;
    });
  }, []);

  return (
    <div className="quiz-results-container">
      <h6>Thanks for Playing</h6>
      <h1>
        You got &nbsp;{userScore} out of &nbsp;{challengeQuestion.length} correct !
       
      </h1>
      <p>
      { userScore < 3 ? 'Nice effort! You know your business, but there may be some opportunities to use your data more effectively. Luckily, we have the eBook just for you.' 
          : 'Way to go! Looks like you know how to use your data, but if you want to brush up, we have an eBook just for you.'
        }
      </p>
      <div className="share-quiz-container">
        <button onClick={() => navigate("/form")}>See The Guide</button>
        <p>Share this quiz</p>
        <div style={{ display: "flex" }} className="social-buttons">
          <FacebookShareButton
            quote="Think you know how to use restaurant data effectively? I took the @HungerRush Data Mastery quiz. Test your know-how at dataquiz.hungerrush.com"
            url={"https://dataquiz.hungerrush.com/"}
          >
            <img src={facebook} alt="facebook icon" />
          </FacebookShareButton>
          <TwitterShareButton
            title="Think you know how to use restaurant data effectively? I took the @HungerRush Data Mastery quiz. Test your know-how at"
            url={"dataquiz.hungerrush.com"}
          >
            <img src={twitter} alt="twitter icon" />
          </TwitterShareButton>
          <LinkedinShareButton
            summary="Think you know how to use restaurant data effectively? I took the @HungerRush Data Mastery quiz. Test your know-how at dataquiz.hungerrush.com"
            url={"https://dataquiz.hungerrush.com/"}
          >
            <img src={linkedin} alt="linkedin icon" className="linkedin-icon" />
          </LinkedinShareButton>
        </div>
      </div>
      <div className="carousel-wrapper">
        <Carousel
          challengeQuestion={challengeQuestion}
          userAnswers={userAnswers}
        />
      </div>
    </div>
  );
}
