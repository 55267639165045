import React from "react";
import "./styles.css";
import bakcground from "../../assets/images/background@2x.webp";
import TitleBar from "../../components/TitleBar/index";
import { useNavigate } from "react-router-dom";

export default function QuizLanding() {
  const navigate = useNavigate();
  return (
    <>
      <TitleBar />
      <div className="quiz-landing-container">
        <div className="quiz-hero-image">
          <img src={bakcground} alt="background img" />
        </div>
        <div className="image-overlay" />

        <div className="quiz-content-container">
          <h6>Test your Knowledge</h6>
          <h1>The Restaurant Data Mastery Quiz</h1>
          <button
            className="start-quiz-button"
            onClick={() => navigate("/quiz")}
          >
            Start the Quiz
          </button>
        </div>
      </div>
    </>
  );
}
